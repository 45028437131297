.order-font{
    font-size: 17px;
    line-height: 24px;
    color: #333a4d;
    
    white-space: normal;
    align-self: center;
    font-family: Avenir Heavy;
}
.prize-label1{
    min-width: 52px;
    font-size: 18px;
    font-family: AvenirLTStd-Roman;
    letter-spacing: -0.11px;
    line-height: 18px;
    right: 0px;
    
    align-items: flex-end;
    display: inline-flex;
    justify-content: right;
    background-repeat: no-repeat;
    border-radius: 0px 8px;
    position: relative;
    padding: 5px 10px;
    
}
.oreder1{
    max-width: 640px;
    margin: 0 auto; 
    margin-top: 20px; 
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  
  }
  .height{
    scroll-behavior: smooth;
    overflow-y: auto;
    max-height: 400px;
    height: 350px;
  }
  .buttons-round{
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    height: 50px;
  width: 50px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }
  button{
    padding: 0;
    border: #535e79;
    font: inherit;
    color: #535e79;
    background-color: transparent;
  }
  button:focus {
    outline: 0;
  }
  button {
    height: 30px;
    width: 30px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
  .button-five {
    background-color: transparent;
    border-radius: 25px;
    transition: .3s;
    
  } 
  .button-4 {
   
    
    color: #ff0251;
    transition: .3s;
  }
  .button-five:hover {
    animation: pulse 1s ;
    transition: .3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(.9);
    }
      100% {
      transform: scale(1);
    }
  }