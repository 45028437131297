.modal-img{
    width: 100%;
    height: 250px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.modal-body{
    padding: 10px;
}
.modal-content{
outline: none;
margin-block: 50px;
border: 0ch;
padding: 10px;
}
.img-rounded{
    border-radius: 10px;
}
.button-block{
    display: flex;
    padding: 0 0 30px 20px;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    background: transparent;
    width: auto;
    justify-content: flex-end;
    z-index: 8;
  }
  .button-block2{
    display: flex;
    padding: 0 0 30px 20px;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: auto;
    background: transparent;
    width: auto;
    justify-content: flex-start;
    z-index: 8;
  }
  .back-button{
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    margin: 0px;
    width: 40px;
    border-radius: 100px;
    border: 0ch;
    left: 10px;
    right: auto;
    z-index: 100;
    height: 40px !important;
    min-width: 40px !important;
    padding: 0px !important;
    opacity: 0.8;
    top: 10px;
  }
  @media only screen and (max-width: 474px){
    .container4{
        position: fixed;
        height: 100%;
        top: 0px;
       
    }
  }
  .container4{
  position: fixed;
    top: 0px;
    z-index: 99;
    width: 100%;

    max-width: 640px;
    transition: all .5;
  
    height: 60px;
  
   
    
  }
  .font-modal{
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.38px;
    margin: 0px 16px 7px;
    color: rgb(83, 94, 121) !important;
    background-color: #f6f6f8;
  }
  .modal-header{
    border: none;
    padding-left: 10px;
    padding-top: 14px;
  }
  .btn-block{
    display: flex;
    padding: 0px 0px 30px 20px;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: auto;
    right: 0px;
    background: transparent;
    width: auto;
    justify-content: flex-end;
    z-index: 8;
  }
  .btn-block2{
    display: flex;
    padding: 30px 20px 0px 0px;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: auto;
    background: transparent;
    width: auto;
    justify-content: flex-end;
    z-index: 8;
  }
  .btn-block3{
    display: flex;
    padding: 0px 0px 50px 0px;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: auto;
    background: transparent;
    width: auto;
    justify-content: flex-start;
    z-index: 8;
  }
  /* button add  */
  .icon-text-btn{
    letter-spacing: -0.36px;
    font-size: 15px !important;
  }
  
  .v-btn{
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
  }
  .v-btn.v-btn--has-bg{
    background-color: #f5f5f5;
  }
  .v-size--default{
    font-size: .875rem;
  }
  .icon-text-btn{
    min-height: 45px;
    font-family: "Avenir Heavy";
    text-transform: capitalize;
    border-radius: 25px 0px 0px 25px;
    letter-spacing: -0.53px;
    min-width: 150px !important;
    font-size: 15px !important;
  }
  .icon-text-btn2{
    min-height: 45px;
    font-family: "Avenir Heavy";
    text-transform: capitalize;
    border-radius: 25px 25px 25px 25px;
    letter-spacing: -0.53px;
    min-width: 150px !important;
    font-size: 15px !important;
  }
  .icon-text-btn3{
    min-height: 45px;
    font-family: "Avenir Heavy";
    text-transform: capitalize;
    border-radius: 0px 25px 25px 0px;
    letter-spacing: -0.53px;
    min-width: 150px !important;
    font-size: 15px !important;
    align-content: center;
  }
  .v-btn--is-elevated{
    box-shadow: 0 3px 1px -2pxrgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  }
  .v-btn{
    align-items: center;
    border-radius: 4px
px
;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: .0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    /* text-transform: uppercase; */
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  .v-btn2{
    align-items: center;
    border-radius: 4px
px
;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: .0892857143em;
    justify-content: center;
    outline: 0;
    position: absolute;
    text-decoration: none;
    text-indent: 0.0892857143em;
    /* text-transform: uppercase; */
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    z-index: 100;
  }
  .v-btn3{
    align-items: center;
    border-radius: 4px
px
;
    display: inline-flex;
    /* flex: 0 0 auto; */
    font-weight: 500;
    letter-spacing: .0892857143em;
    justify-content: center;
    outline: 0;
    position: absolute;
    text-decoration: none;
    text-indent: 0.0892857143em;
    /* text-transform: uppercase; */
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    z-index: 100;
  }
  .example::-webkit-scrollbar {
    display: none;
  }
  