.image2 {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    /* border: 1px green solid; */
  }
  .image4 {
    position: absolute;
    top: 30px;
    left: 30px;
    cursor: pointer;
    /* border: 1px green solid; */
  }