a:hover {
    text-decoration: none!important;
  }
  .dedcription-btn {
    width: 25%;
    position: absolute;
    display: inline-block;
    border-radius: 30px;
    background-color: #fcfcfc;
    color: #ffa000;
    text-align: center;
    font-size: 18px;
    padding: 9px 0;
    transition: all 0.3s;
    padding-right: 40px;
    margin: 20px 5px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
    z-index: 100;
    top: 30px;
    left: 30px;
    cursor: pointer;
    text-decoration: none;

  }
  .dedcription-btn .btn-icon {
    background-color: #ff7300;
    width: 92px;
    height: 45px;
    float: right;
    position: absolute;
    border-radius: 30px 30px 30px 0;;
    right: 0px;
    top: 0px;
    transition: all 0.3s;
  }
  .name-descripeion {
    position: relative;
    z-index: 9999;
  }
  .btn-icon::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 45px solid #fcfcfc;
    border-right: 40px solid transparent;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .dedcription-btn:hover .btn-icon {
    width: 100%;
    border-radius: 30px;
  }
  .dedcription-btn:hover .btn-icon::after {
    display: none;
    opacity: 0.1;
  }
  .btn-icon i {
    position: absolute;
    right: 25px;
    top: 15px;
    color: #fff;
  }
  .dedcription-btn:hover {
    color: #fff!important;
  }
  .heart {
    background-color: #ff586b!important;
  }
  .book {
    background-color: #f7541b!important;
  }
  .brain {
    background-color: #8bc34a!important;
  }
  .hover-box {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 600px) {
    .dedcription-btn {
        width: 30%;
        position: absolute;
        display: inline-block;
        border-radius: 30px;
        background-color: #fcfcfc;
        color: #ffa000;
        text-align: center;
        font-size: 18px;
        padding: 9px 0;
        transition: all 0.3s;
        padding-right: 40px;
        margin: 20px 5px;
        box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
        z-index: 100;
        top: 10px;
        left: 10px;
        cursor: pointer;
        text-decoration: none;
    
      }
  }