.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.font-color1 {
  font-size: medium;
  color: #f7541b;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.fa-brands {
  color: #f7541b;
}

.container1 {
  max-width: 680px;
  margin: 0 auto;
  /* margin-top: 20px;  */
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.card {
  border-radius: 20px;
}
.prize-label {
  min-width: 52px;
  font-size: 18px;
  font-family: AvenirLTStd-Roman;
  letter-spacing: -0.11px;
  line-height: 18px;
  right: 0px;
  min-height: -10ppx !important;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-repeat: no-repeat;
  border-radius: 0px 8px;
  position: absolute;
  padding: 5px 10px;
  top: 0px !important;
}
.font-color {
  color: #f7541b;
}
.img-style {
  width: 450px;
  height: 303px;
}
.img-style2 {
  width: 48px;
  height: 48px;
}
.card-image {
  align-items: center;
  border-radius: 10%;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  width: 80px;
  height: 80px;

  padding-right: 5px;
}
.curve {
  border-radius: 8px !important;
}
.curve2 {
  border-radius: 25px !important;
}
.font-size {
  font-size: 18px;
  font-family: AvenirLTStd-Roman;
}
.card-details {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  font-family: AvenirLTStd-Roman;
  margin: 5px 0px 0px;
  color: rgb(106, 106, 106);
  white-space: normal;
  padding-inline: 5px;
  text-align: left;
}
.card-heading {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
  font-family: "Avenir Heavy";
  margin: 0px;
  padding-right: 58px;
  white-space: normal;
  text-align: left;
}
.button-colors {
  background-color: #f7541b !important;
  caret-color: rgb(83, 94, 121) !important;
  color: rgb(255, 255, 255) !important;
  
}

.salad-button {
  min-width: 52px;
  font-size: 18px;
  font-family: AvenirLTStd-Roman;
  letter-spacing: -0.11px;
  line-height: 18px;
  right: 0px;
  min-height: 32px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-repeat: no-repeat;
  border-radius: 0px 8px;
  position: absolute;
  padding: 5px 10px;
}
.salad-button1 {
  right: 0px;
  left: auto;
  bottom: auto;
  border-radius: 0px 0px 0px 8px;
  min-height: 32px;
  top: 170px !important;
}
.pokkam {
  height: 170px !important;
  width: 100% !important;
  margin: 0 !important;
}
.beverage-button {
  min-width: 52px;
  font-size: 18px;
  font-family: AvenirLTStd-Roman;
  letter-spacing: -0.11px;
  line-height: 18px;
  right: 0;
  top: 0 !important;
  min-height: 32px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-repeat: no-repeat;
  border-radius: 0 8px 0 8px;
  position: absolute;
  padding: 5px 10px;
}
.pokkam1 {
  width: 100%;
}
.button-colors1 {
  background-color: rgb(83, 94, 121) !important;
  caret-color: rgb(83, 94, 121) !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.8;
}
.card {
  border-radius: 15px !important;
}
.container2 {
  max-width: 680px;
  margin: 0 auto;
  background-color: #e3dcca;
  /* background-image: url(https://thumbs.dreamstime.com/b/coffee-seamless-line-pattern-cartoon-background-30885451.jpg); */
}

/* .modal-image{
  height: auto;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: relative;
}
.modal-header{
  font-size: 20px;
    line-height: 25px;
    letter-spacing: .38px;
    margin: 0 16px 7px;
    
}
.modal-header1{
  font-size: 15px;
    line-height: 25px;
    letter-spacing: .38px;
    margin: 0 16px 7px;
    background-color: #f1f1f1;
    
}
.modal-btn{
  display: flex;
  padding: 0 0 30px 20px;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  background: transparent;
  width: auto;
  justify-content: flex-end;
  z-index: 8;
}
.btn-color{
  
    letter-spacing: -.36px;
  min-height: 45px;
  font-family: "Avenir Heavy";
  text-transform: capitalize;
  border-radius: 25px 0px 0px 25px;
  letter-spacing: -0.53px;
  min-width: 197px !important;
  font-size: 22px !important;
  background-color: rgb(83, 94, 121) !important;
    caret-color: rgb(83, 94, 121) !important;
    color: rgb(255, 255, 255) !important;
    
}
.modal-v-button{
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: .0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  
  transition-duration: .28s;
  transition-property: box-shadow,transform,opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  font-size: 13px;

} */

.scroll {
  max-height: 400px;
  overflow-y: auto;
}
.card-details1 {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.32px;
  color: #6a6a6a;
  margin-bottom: 0;
}
.currency {
  font-size: 9px;
}
.heading-color {
  color: #f7541b;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
}
.footer-block {
  position: fixed;
  bottom: 24px;
  padding: 0 24px !important;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  max-width: 680px;
  margin: 0 auto;
}
.round-footer {
  height: 45px;
  min-width: 264px;
  max-width: 680px !important;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: normal;
  font-family: Avenir Heavy;

  border-radius: 28px;
  align-items: center;
}
.footer-s {
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
}
.content-footer {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}
.footer-color {
  background-color: #f7541b !important;
  caret-color: rgb(83, 94, 121) !important;
  color: rgb(255, 255, 255) !important;
}
.footer1 {
  max-width: 680px;
  margin: 0 auto;
}
.content-footer {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
  height: 45px;
  max-width: 680px;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: normal;
  font-family: "Avenir Heavy";
  border-radius: 20px;
}
.footer-color {
  background-color: #f7541b !important;
  caret-color: rgb(83, 94, 121) !important;
  color: rgb(255, 255, 255) !important;
}
